'use strict';

// Styles
require('../styles/main.scss');

// Images
require('../images/arrow.svg');
require('../images/burger.svg');
require('../images/logo.svg');
require('../images/wordmark.svg');
require('../images/loader.gif');
require('../images/logo_inv.svg');
require('../images/marker.png');

// Dependencies
const $ = require('jquery');
require('jquery-backstretch');
require('@fancyapps/fancybox');

// Make jquery globally available
global.$ = global.jQuery = $;

$(() => {
    const $elementsToHide = $('.mobilenavhide');
    const $mobilenav = $('.mobilenav').find('ul').first();
    const $window = $(window);
    $('.navtoggle').on('click', function(e) {
        e.preventDefault();
        const $body = $('body');
        if (!$body.hasClass('navexpanded')) {
            $body.addClass('navexpanded');
            $elementsToHide.fadeOut('fast');
            $mobilenav.css('top', 0);
        } else {
            $body.removeClass('navexpanded');
            $elementsToHide.fadeIn('fast');
            $mobilenav.css('top', '-100%');
        }
    });

    const $teamsection = $('.team');
    $teamsection.find('a').not('.linked').on('click', function(e) {
        e.preventDefault();
    });

    const $arrowtop = $('.arrow');
    $arrowtop.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: 0}, 500);
    });
    let viewport = $window.height();
    const setButtonState = (scroll) => {
        $arrowtop.toggleClass('active', scroll >= viewport/2);
    };
    let scroll = $window.scrollTop();
    setButtonState(scroll);
    $window.on('scroll', () => {
        scroll = $window.scrollTop();
        setButtonState(scroll);
    });

    const $slider = $('[data-slider]');
    if ($slider.length) {
        console.log($slider.length)
        const images = $slider.data('images');
        const duration = $slider.data('duration');
        const fade = $slider.data('fade');
        $slider.backstretch(images, { duration: duration, fade: fade });
    }
});
